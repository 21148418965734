@import "~app/styles/mixins.less";
@import "~app/styles/buttons.less";

* {
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
}

html {
    text-size-adjust: 100%;
    font-size: 62.5%;
}

body {
    margin: 0;
    min-width: 360px;
    overflow-x: auto;
    background-color: #eef2f4;
    font-size: 1.4rem;
}

html,
body,
app-root {
    height: 100%;
}

.page-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

a {
    text-decoration: none;
    color: #3f5763;
    display: block;
}

.btn-transparent,
textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="date"],
select {
    padding: 11px;
    cursor: pointer;
    outline: none;
    appearance: none;
    border-radius: 6px;
    border: solid 1px #3f5763;
    color: #3f5763;
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-size: 1.4rem;

    &::placeholder {
        color: #3f5763;
    }

    &.ng-touched.ng-invalid,
    &.ng-submitted.ng-invalid,
    &.touched.invalid {
        border-color: #f4336a;
    }
}

.ng-submitted {
    select.ng-invalid {
        border-color: #f4336a;
    }
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: #fff;
    background-image: none;
    appearance: none;
    border: solid 1px #3f5763;
    cursor: pointer;
    margin: 0;
    vertical-align: middle;

    + label {
        cursor: pointer;
    }

    &:checked {
        .background("check");

        background-size: 72% 60%;

        + label {
            font-weight: bold;
        }
    }

    &:disabled + label {
        cursor: not-allowed;
    }

    &.no-border {
        border: none;
    }
}

input[type="date"] {
    padding: 9px 12px;
}

input {
    &:disabled {
        cursor: not-allowed;
    }
}

select {
    .background("arrow-down");

    padding: 10px 30px 10px 10px;
    background-size: 13px 8px;
    background-position: center right 12px;
}

textarea {
    width: 100%;
    padding: 12px;
    font-weight: bold;
    font-size: 1.3rem;
    letter-spacing: 0.25px;
    color: #3f5763;
    resize: none;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.alert-dialog .mat-dialog-container {
    padding: 32px 36px 39px;
    border-radius: 8px;
}

.highlight {
    display: inline;
    color: #f4336a;
    font-weight: 600;
}

.invisible {
    display: none;
}

strong {
    color: #f4336a;
}

.loader {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    &::after {
        margin: 60px 0;
        content: "";
        width: 10em;
        height: 10em;
        text-indent: -9999em;
        border: 1.1em solid #b9439b;
        border-bottom-color: transparent;
        border-radius: 50%;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
